import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import { styles } from "../../shared-styles";
import {blogContent} from "./{mdx.slug}.module.css";


const BlogPost = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image);

  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <section className={blogContent} style={styles.ContentSection}>

        <p style={{textDecoration: "underline", textAlign: "end", marginBottom: "35px"}}>Publicat <strong>{data.mdx.frontmatter.date}</strong></p>
        {
          data.mdx.frontmatter.hero_image_alt &&
          <GatsbyImage
            image={image}
            alt={data.mdx.frontmatter.hero_image_alt}
          />
        }
        {
          data.mdx.frontmatter.hero_image_alt &&
          <p>
            Photo Credit:{" "}
            <a href={data.mdx.frontmatter.hero_image_credit_link}>
              {data.mdx.frontmatter.hero_image_credit_text}
            </a>
          </p>
        }
        <MDXRenderer>
          {data.mdx.body}
        </MDXRenderer>
      </section>
    </Layout>
  );
};

export const query = graphql`
    query($id: String) {
        mdx(id: {eq: $id}) {
            frontmatter {
                title
                date(formatString: "DD MMMM, YYYY", locale: "ro-RO")
                hero_image_alt
                hero_image_credit_link
                hero_image_credit_text
            }
            body
        }
    }
`;

export default BlogPost;
